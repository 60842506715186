import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { RequireAuth } from 'components/functional/RequireAuth/RequireAuth';
import { Header } from 'components/general/Header/Header';

export function AppLayout() {
  const location = useLocation();

  const tabs = [
    {
      title: 'Dashboard',
      link: '/app/dashboard',
    },
  ];

  if (location?.pathname === '/app') {
    return <Navigate to="/app/dashboard" replace />;
  }

  return (
    <RequireAuth>
      <div className="min-h-screen bg-background-dark">
        <div className="container bg-background-dark">
          <div className="py-4">
            <Header />
            <div className="mb-3 ml-1 mt-3 flex w-full border-b-2 border-background-light text-background-contrastText">
              {tabs.map((tab) => (
                <NavLink
                  key={tab.title}
                  to={tab.link}
                  className={({ isActive }) =>
                    `ml-4 mr-3 inline-block px-4 py-2 text-lg font-bold  ${
                      isActive ? 'text-primary-main ' : 'text-zinc-400 '
                    }`
                  }>
                  <div>
                    <Typography style={{ color: 'inherit' }} variant="h3">
                      {tab.title}
                    </Typography>
                  </div>
                </NavLink>
              ))}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </RequireAuth>
  );
}
