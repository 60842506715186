import { Navigate, createBrowserRouter } from 'react-router-dom';

import { Page } from 'components/common/page/Page';
import { RequireAuth } from 'components/functional/RequireAuth/RequireAuth';
import { AppLayout } from 'pages/app/AppLayout';
import { Dashboard } from 'pages/app/Dashboard/Dashboard';
import { Page404 } from 'pages/app/misc/Page404';
import { UserProfile } from 'pages/app/user-profile/UserProfile/UserProfile';
import { UserProfileLayout } from 'pages/app/user-profile/UserProfileLayout';
import { UserSetting } from 'pages/app/user-profile/UserSetting/UserSetting';
import { Redirect } from 'pages/redirect/Redirect';

export const routes = createBrowserRouter([
  {
    path: '/redirect',
    element: (
      <Page title="Login">
        <Redirect />
      </Page>
    ),
  },
  {
    path: '',
    element: (
      <RequireAuth>
        <Navigate to="/app" />
      </RequireAuth>
    ),
  },
  {
    path: '/app',
    element: <AppLayout />,
    errorElement: <Page404 />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Page title="Dashboard">
            <Dashboard />
          </Page>
        ),
      },
      {
        path: 'user',
        element: <UserProfileLayout />,

        children: [
          {
            path: 'profile',
            element: (
              <Page title="Profile">
                <UserProfile />
              </Page>
            ),
          },
          {
            path: 'settings',
            element: (
              <Page title="Settings">
                <UserSetting />
              </Page>
            ),
          },
        ],
      },
    ],
  },
]);
