import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { InferType } from 'yup';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { searchPatientSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { ProfileService } from 'services/profile';

type FormDataType =
  | InferType<typeof searchPatientSchema>
  | { [x: string]: string | undefined };

export function Dashboard() {
  const patientIdentityMutation = useMutationWithToast(
    ProfileService.getPatientIdentity
  );

  const formMethods = useForm<FormDataType>({
    resolver: yupResolver(searchPatientSchema),
    defaultValues: {
      search: '',
    },
  });
  const { isDirty } = formMethods.formState;
  const patientLicenseImageUrl =
    patientIdentityMutation.data?.data.patientDriversLicense?.licenseImageUrl;
  const patientPhotoIdentityUrl =
    patientIdentityMutation.data?.data.patientPhotoIdentity?.photoIdentityUrl;

  const onSearch = formMethods.handleSubmit((data) => {
    if (!isDirty) {
      return;
    }
    patientIdentityMutation.mutate(data.search!);
  });

  return (
    <div className="mt-10 ">
      <div className="flex justify-between space-x-4">
        <TextInput
          size="small"
          control={formMethods.control}
          name="search"
          placeholder="Search with patient or order Id."
          fullWidth
          className="w-full rounded-md bg-background-light !py-2.5 px-4"
          helperText={
            extractValidationErrors(
              'search',
              patientIdentityMutation,
              formMethods.formState.errors
            ).msg
          }
          error={
            extractValidationErrors(
              'search',
              patientIdentityMutation,
              formMethods.formState.errors
            ).hasError
          }
        />
        <Button
          className="w-max !py-2.5"
          disabled={patientIdentityMutation.isLoading}
          onClick={onSearch}
          loading={patientIdentityMutation.isLoading}>
          Search
        </Button>
      </div>
      <div>
        {patientIdentityMutation.isSuccess &&
          (!patientPhotoIdentityUrl && !patientLicenseImageUrl ? (
            <Typography
              color="error"
              className="mt-2 rounded-md border border-background-light px-4 py-4"
              variant="subtitle2">
              Patient has not provided driving license or any other photo
              identity.
            </Typography>
          ) : (
            <figure className="relative mt-2 flex gap-10">
              <div
                className={classNames('grid gap-3 ', {
                  ' !hidden ': !patientLicenseImageUrl,
                })}>
                <Typography variant="subtitle2">Patient License</Typography>
                <img
                  className="h-[300px] overflow-hidden rounded-lg object-contain"
                  src={patientLicenseImageUrl}
                  alt="License"
                />
              </div>
              <div
                className={classNames('grid gap-3', {
                  ' !hidden ': !patientPhotoIdentityUrl,
                })}>
                <Typography variant="subtitle2">
                  Patient Photo Identity
                </Typography>
                <img
                  className="h-[300px] overflow-hidden rounded-lg object-contain"
                  src={patientPhotoIdentityUrl}
                  alt="Identity"
                />
              </div>
            </figure>
          ))}
      </div>
    </div>
  );
}
