import React, { useEffect, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { setAccessToken, setRefreshToken } from 'lib/general/token';
import { AuthenticationService } from 'services/authentication';

export function Redirect() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(1);
  const [isError, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('login_token');
  const getLoginTokenQuery = useQuery(
    [],
    () => AuthenticationService.tokenLogin({ loginToken: token as string }),
    {
      retry: false,
      enabled: !!token,
      onSuccess: ({ data: { accessToken, refreshToken } }) => {
        setTimeout(() => setProgress(100), 1000);
        Promise.all([
          setAccessToken(accessToken),
          setRefreshToken(refreshToken),
        ]).then(() => {
          queryClient.invalidateQueries([QueryKeys.UserProfile]);
        });
      },
      onError: () => {
        setError(true);
      },
    }
  );
  // eslint-disable-next-line
  console.log(getLoginTokenQuery);

  useEffect(() => {
    if (progress) {
      setTimeout(() => setProgress(97), 50);
      if (progress === 100) {
        navigate('/app/dashboard');
      }
    }
  }, [progress, navigate]);

  return (
    <div className=" flex h-screen w-full flex-col items-center justify-center bg-background-dark">
      {isError ? (
        <div className="flex flex-col items-center justify-center rounded bg-background-main px-20 py-12">
          <Typography variant="h4">
            Something went wrong. Please login again
          </Typography>
          <Button
            onClick={() =>
              window.location.replace(
                `${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`
              )
            }
            className="mt-4"
            variant="contained">
            Login
          </Button>
        </div>
      ) : (
        <>
          {' '}
          <Typography variant="subtitle1">Loading</Typography>
          <div className=" mt-2  h-2 w-60  rounded bg-background-light">
            <div
              style={{
                width: `${progress}%`,
              }}
              className="h-2 rounded bg-background-contrastText transition-all duration-[500ms]"
            />
          </div>
        </>
      )}
    </div>
  );
}
