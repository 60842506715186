import * as yup from 'yup';

const phone = (required = true) => {
  const phoneSchema = yup
    .string()
    .min(11, 'Please enter valid phone')
    .max(13, 'Please enter valid phone');

  return required ? phoneSchema.required('Phone is required') : phoneSchema;
};

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email.')
    .required('Please enter email.'),
});

export const phoneSchema = yup.object().shape({
  phone: phone(true),
});

export const updatePasswordSchema = yup.object({
  oldPassword: yup.string().required('Please enter your current password.'),
  newPassword: yup.string().required('Please enter your new password.'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
});

export const searchPatientSchema = yup.object().shape({
  search: yup
    .string()
    .required('Please enter patient or order Id.')
    .max(8, 'Patient or order Id must have 8 characters.')
    .min(8, 'Patient or order Id must have 8 characters.'),
});
