import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Controller, Control, FieldValues } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { ControllerOnChangeType } from 'types/input-types';

import 'react-phone-input-2/lib/style.css';
import { Typography } from '../Typography/Typography';
// type ColorType = 'primary' | 'secondary' | 'error' | 'info' | 'success';
type Size = 'small' | 'medium' | 'large';

const sizeClasses: { [S in Size]: string } = {
  small: 'px-4 py-[0.4rem] placeholder:text-base text-md',
  medium: 'px-2 py-3 placeholder:text-md text-md',
  large: 'px-3 py-3.5 placeholder:text-lg text-lg',
};

type InputType = 'text' | 'email' | 'number' | 'password' | 'tel' | 'date';
type MultilineProps =
  | {
      multiline?: true;
      rows: number;
    }
  | {
      multiline?: false;
      rows?: never;
    };

type ControlProps =
  | {
      control?: unknown;
      name: string;
    }
  | {
      control?: false | undefined;
      name?: never;
    };

type InputProps = {
  size?: Size;
  className?: string;
  helperText?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  type?: InputType;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  disableHelperText?: boolean;
  defaultValue?: string;
  id?: string;
  label?: string;
  step?: string;
  onBlur?: () => void;
} & MultilineProps &
  ControlProps;

export function TextInput({
  type = 'text',
  size = 'medium',
  className,
  helperText,
  error,
  placeholder,
  value,
  onChange,
  disabled,
  fullWidth,
  multiline,
  rows = 6,
  control,
  name,
  disableHelperText,
  defaultValue,
  id,
  label,
  step,
  onBlur,
}: InputProps) {
  const [showPassword, setShowPassword] = useState<string>();
  useEffect(() => {
    if (type) {
      setShowPassword(type);
    }
  }, [type]);

  const classes = useMemo(
    () =>
      classNames(
        `rounded-md shadow-sm   focus:outline-none placeholder:text-zinc-400 bg-background-light w-full text-background-contrastText ${sizeClasses[size]}`,
        className,
        {
          'opacity-60 cursor-not-allowed': disabled,
        },
        {
          'border !border-error-main': !!error,
        }
      ),

    [className, disabled, error, size]
  );

  const getInput = useCallback(
    (
      controlChange?: ControllerOnChangeType,
      formValue?: FieldValues['value']
    ) =>
      multiline ? (
        <textarea
          id={id}
          name={name ?? ''}
          className={classes}
          placeholder={placeholder}
          value={control ? formValue : value}
          onChange={
            control
              ? controlChange
              : (e) => {
                  onChange?.(e.target.value);
                }
          }
          disabled={disabled}
          rows={rows}
          defaultValue={defaultValue}
        />
      ) : name === 'phone' || type === 'tel' ? (
        <PhoneInput
          inputProps={{
            name,
            className: classNames(classes, 'pl-12 !z-5'),
            id,
            disabled,
          }}
          value={control ? formValue || defaultValue : value || defaultValue}
          onChange={
            control
              ? (phone: string) =>
                  controlChange?.({
                    target: { value: phone, name: name || '' },
                  })
              : (phone: string) => {
                  onChange?.(phone);
                }
          }
          placeholder={placeholder}
          disabled={disabled}
          enableSearch
          disableSearchIcon
          searchClass="[&>input]:!border-none !px-0.5 w-full !pt-2 !pb-1 !mb-1 !border-b !border-b-zinc-400 !bg-background-light !text-background-contrastText !outline-none"
          containerClass="relative !rounded-2xl !bg-background-light !mb-2.5"
          dropdownClass="!absolute !w-full !p-2 !pt-0 [&>li[role]]:!rounded-sm [&>li[role]]:!p-2 [&>li[role]]:!text-background-contrastText [&>li[role]:hover]:!bg-primary-main [&>li[role]:hover>span]:!text-primary-contrastText [&>li[aria-selected]]:!bg-primary-main [&>li[aria-selected]>span]:!text-white !bg-background-light !text-background-contrastText !ml-0 !mt-11 !shadow-md"
          buttonClass={classNames(
            "!bg-transparent !flex !-mt-[37.5px] !ml-0.5 !border-2 !static !h-[30px] [&>div[role='button']]:!bg-background-light !border-none [&>div[role='button']]:!rounded-sm"
          )}
          searchPlaceholder="Search"
          disableDropdown={process.env.REACT_APP_ENVIRONMENT === 'production'}
          country="us"
        />
      ) : (
        <div className="relative">
          <input
            id={id}
            name={name ?? ''}
            type={showPassword ?? type}
            className={classNames(classes)}
            placeholder={placeholder}
            value={control ? formValue : value}
            step={step}
            onBlur={onBlur}
            onChange={
              control
                ? controlChange
                : (e) => {
                    onChange?.(e.target.value);
                  }
            }
            disabled={disabled}
            defaultValue={defaultValue}
          />
          {type === 'password' &&
            (showPassword === 'password' ? (
              <EyeSlashIcon
                onClick={() => setShowPassword('text')}
                className="absolute right-4 top-3 h-[20px] cursor-pointer text-zinc-400"
              />
            ) : (
              <EyeIcon
                onClick={() => setShowPassword('password')}
                className="absolute right-4 top-3 h-[20px] cursor-pointer text-zinc-400"
              />
            ))}
        </div>
      ),
    [
      classes,
      disabled,
      multiline,
      onChange,
      placeholder,
      onBlur,
      rows,
      type,
      value,
      control,
      defaultValue,
      id,
      name,
      showPassword,
      step,
    ]
  );

  return (
    <div
      className={classNames('relative w-60 ', {
        ' w-full ': fullWidth,
      })}>
      {label && (
        <label htmlFor={name}>
          <Typography className="mb-1" variant="subtitle3">
            {label}
          </Typography>
        </label>
      )}
      {control ? (
        <Controller
          name={name as string}
          control={control as Control}
          render={({
            field: { onChange: controlChange, value: fieldValues },
          }) => getInput(controlChange, fieldValues)}
        />
      ) : (
        getInput()
      )}
      {!disableHelperText && (
        <p
          className={classNames(
            'my-1 h-[0.8rem] text-left  text-sm text-background-contrastText',
            {
              ' !text-error-main': !!error,
            }
          )}>
          {helperText}
        </p>
      )}
    </div>
  );
}
