import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { updatePasswordSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { ProfileService } from 'services/profile';

type UpdatePasswordProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
};
export function UpdatePassword({ modal, closeModal }: UpdatePasswordProps) {
  const updatePasswordMutation = useMutationWithToast(
    ProfileService.updateUserPassword
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const onSubmit = handleSubmit((data) => {
    updatePasswordMutation.mutate(
      { oldPassword: data.oldPassword, password: data.newPassword },
      {
        onSuccess: () => {
          closeModal(false);
          reset();
        },
      }
    );
  });

  return (
    <div>
      <Modal
        title="Change Password"
        open={modal}
        className="w-2/5"
        closeModal={closeModal}>
        <div className="mt-4">
          <TextInput
            className="pl-2 "
            type="password"
            name="oldPassword"
            label="Current Password"
            control={control}
            helperText={
              extractValidationErrors(
                'oldPassword',
                updatePasswordMutation,
                errors
              ).msg
            }
            error={
              extractValidationErrors(
                'oldPassword',
                updatePasswordMutation,
                errors
              ).hasError
            }
            placeholder="Current Password"
          />
          <TextInput
            className="pl-2"
            type="password"
            name="newPassword"
            label="New Password"
            control={control}
            helperText={
              extractValidationErrors(
                'newPassword',
                updatePasswordMutation,
                errors
              ).msg
            }
            error={
              extractValidationErrors(
                'newPassword',
                updatePasswordMutation,
                errors
              ).hasError
            }
            placeholder="New Password"
          />
          <TextInput
            className="pl-2"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            control={control}
            helperText={errors.confirmPassword?.message?.toString()}
            error={!!errors.confirmPassword?.message}
            placeholder="Confirm Password"
          />

          <div>
            <div className="mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  updatePasswordMutation.reset();
                  reset();
                  closeModal(false);
                }}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updatePasswordMutation.isLoading}
                onClick={onSubmit}>
                Update Password
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
