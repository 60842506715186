// eslint-disable-next-line import/extensions
import LogoGreen from 'assets/images/LogoGreen(OnLeft).svg';
import { AvatarDropdown } from 'components/general/AvatarDropdown/AvatarDropdown';

export function Header() {
  return (
    <div className="w-full  rounded bg-background-main py-2  ">
      <div className="flex justify-between px-8">
        <img className="w-48" src={LogoGreen} alt="logo" />
        <div className="flex items-center space-x-4">
          <AvatarDropdown />
        </div>
      </div>
    </div>
  );
}
